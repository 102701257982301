<!-- "/walls2 -->
<!-- corridor with controller  -->

<template>
  <h3 id="controlsInfos">Double click to look around with the mouse <br> Arrows up(w,h,f) or down(s,b) to move</h3>
  <div id="container"></div>

  <!-- Mobile Controlers -->
  <i id="fwd" v-if="isMobile" class="fas fa-angle-right"></i>
  <i id="bwd" v-if="isMobile" class="fas fa-angle-right"></i>


  <!-- maybe for performance  -->
  <div id="blocker"></div>
</template>

<script>
  import * as THREE from 'three'
  //import { TrackballControls } from 'three/addons/controls/TrackballControls.js';
  //import { FirstPersonControls } from 'three/addons/controls/FirstPersonControls.js';
  import { PointerLockControls } from 'three/addons/controls/PointerLockControls.js';
  //import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
  import { CSS3DRenderer, CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js';

  import deviceDetector from '@/tools/DeviceDetector';
  

  export default {
    name: 'CorridorViewer',
    components: {
    },
    props: {
      walls: Array,
    },
    data(){
      return {
        floorLength: 0,
        speed: 15,
        keyForward:['w', 'f', 'h', 'ArrowUp'],
        keyBackward:['s', 'b', 'ArrowDown'],
        keyRight:['d', 'ArrowRight'],
        keyLeft:['a', 'ArrowLeft'],

        isMobile: false,
      }
    },
    methods: {
      mobileVerif(){
        if (this.isMobile){
          document.getElementById('controlsInfos').style.display = 'none'
        }
        return;
      },
      //make three 
      main(){

        let camera, scene, renderer;
        let controls;

        function createWallDOM(wallTitle, imgURL, descritpion){
          const wallElem = document.createElement('div');
          wallElem.classList.add('aWall');
          //title
          const wallTitleElem = document.createElement('h1');
          wallTitleElem.textContent = wallTitle;
          wallTitleElem.classList.add('wallTitle');
          //image
          const wallImgElem = document.createElement('img');
          wallImgElem.src = imgURL;
          wallImgElem.crossorigin ="anonymous";
          //description
          const wallDescritpion = document.createElement('p');
          wallDescritpion.textContent = descritpion
          wallDescritpion.classList.add('description')


          wallElem.appendChild(wallTitleElem);
          wallElem.appendChild(wallImgElem);
          wallElem.appendChild(wallDescritpion);

          return wallElem;
        }

        //create css3d object of the wall and position it
        function Element(aWall, x,y,z,ry){
          const elem = createWallDOM(aWall.title, aWall.imgURL, aWall.description);

          const object = new CSS3DObject(elem);
          object.position.set(x,y,z);
          object.rotation.y = ry;

          return object;
        }

        //add ALL walls to the scene
        function addWalls(wallsTotDisplay){
          const group = new THREE.Group();
          //will determine if the itterated wall is at an even postion in the list
          var even = true;

          for (let i=1; i<wallsTotDisplay.length+1; i++){
            var x = (1597/2)+55;
            var y = 334;
            var z = 610*i*.89;
            var ry = -Math.PI/2

            if (!even){
              x  = -((1597/2)+55);
              ry = Math.PI/2;
              z -= 610/2  
            }

            group.add( new Element(wallsTotDisplay[i-1], x,y,z, ry));

            even = !even;
          }

          scene.add( group );
        }

        function addFloor(floorLength) {
          //add the floor as tiles (css3d div) one after the other otherwise the floor wasnt appearing on mobile chrome browsers
          const tileWidth = 1597;
          const tileHeight = 1000;

          const numTiles = Math.ceil(floorLength / tileHeight);

          for (let i = 0; i < numTiles; i++) {
            const tile = document.createElement('div');
            tile.style.backgroundColor = 'var(--light)'; // Customize as needed
            tile.style.width  = tileWidth.toString() + 'px';
            tile.style.height = tileHeight.toString() + 'px'; 

            const tileObject = new CSS3DObject(tile);
            //position.set(x,y,z) looking forward: x is on the left side y is up z is forward
            tileObject.position.set(0, -420, (tileHeight*(i-1)));
            tileObject.rotation.x = Math.PI / 2;
            scene.add(tileObject);
          }
        }


        function addControls(keyBackward, keyForward, keyRight, keyLeft, speed, floorLength, isMobile){
          //user can look around with the mouse after double click =>
          controls = new PointerLockControls(camera, renderer.domElement);

          const blocker = document.getElementById( 'blocker' );
          blocker.style.display = 'none';
          controls.addEventListener('lock', function(){
            blocker.style.display = 'none';
          });
          controls.addEventListener('unlock', function(){
            blocker.style.display = '';
          });

          function addMobileControl(){
            let fwdInterval;
            //for look around
            let startX, startY;
            //move fwd
            document.getElementById("fwd").addEventListener("touchstart", (e) => {
              e.preventDefault();
              fwdInterval = setInterval(moveForward, 22);
            });
            document.getElementById("fwd").addEventListener("touchend", (e) => {
              clearInterval(fwdInterval)
            });
            //move bwd
            document.getElementById("bwd").addEventListener("touchstart", (e) => {
              e.preventDefault();
              fwdInterval = setInterval(moveBackward, 22);
            });
            document.getElementById("bwd").addEventListener("touchend", (e) => {
              clearInterval(fwdInterval)
            });

            //side look
            document.getElementById("container").addEventListener("touchstart", (e) =>{
              startX = e.touches[0].clientX
              startY = e.touches[0].clientY
            });
            document.getElementById("container").addEventListener("touchmove", (e) => {
              if (e.touches.length == 1){
                //only one finger two move 2 to zoom
                move(e.touches[0].clientX, e.touches[0].clientY)
              }
            });

            //Looks around left right
            function move(x, y){
              const deltaX = x - startX
              if(deltaX<0){
                controls.lookRight();
              }
              else if(deltaX>=0){
                controls.lookLeft();
              }
            }
          };


          //shake camera when user hit thee end
          function shakeIt(){
            const force = 6;
            //'shakes the screen'
            camera.rotation.z -= (.1*force);
            camera.position.z -= (3*force);
            setTimeout(() => {
              camera.rotation.z += (.1*force);
              camera.position.z += (3*force);
            }, 122);
            camera.rotation.z += (.1*force);
            camera.position.z -= (3*force);
            setTimeout(() => {
              camera.rotation.z -= (.1*force);
              camera.position.z += (3*force);
            }, 122);
            
          }

          //front and back controls
          function moveForward(){
            var vector = new THREE.Vector3()
            vector = camera.getWorldDirection(vector)
            //in wich cadran the camera looks
            const theta = Math.atan2(vector.x,vector.z);

            //when w or front arrow are clicked
            //when face forward
            const pi = Math.PI
            //q1|q2
            //q4|q3
            const q1 = (theta>=0 && theta<=(pi/2))
            const q2 = (theta<0 && theta>(-pi/2))
            const q3 = (theta<=(-pi/2))
            const q4 = (theta>pi/2)

            if(q1 || q2){
              if(camera.position.z+speed >= floorLength-(420*3)){
                shakeIt()

                return
              }
              camera.position.z += speed
            }
            //when faces back
            else if(q3 || q4){
              if(camera.position.z-speed <= -370){
                shakeIt()

                return
              }
              camera.position.z -= speed
            }
            else{
              console.log(   'Problem')
            }
          }
          function moveBackward(){
            var vector = new THREE.Vector3()
            vector = camera.getWorldDirection(vector)
            const theta = Math.atan2(vector.x,vector.z);

            //when w or front arrow are clicked
            const pi = Math.PI
            //q1|q2
            //q4|q3
            const q1 = (theta>=0 && theta<=(pi/2))
            const q2 = (theta<0 && theta>(-pi/2))
            const q3 = (theta<=(-pi/2))
            const q4 = (theta>pi/2)
            //when facing forward it goes "bac"
            if(q1 || q2){
              if(camera.position.z-speed <= -420){
                shakeIt()
                return
              }
              camera.position.z -= speed
            }
             //when facing backward it goes "front"
            else if(q3 || q4){
              if(camera.position.z+speed >= floorLength-(370*3)){
                shakeIt()
                return
              }
              camera.position.z += speed
            }
            else{
              console.log('   Problem')
            }
          }

          controls.lookRight = function () {
            const euler = new THREE.Euler( 0, 0, 0, 'YXZ' );
            const scope = controls;
            const PI_2 = Math.PI / 2;
            const changeEvent = {
              type: 'change'
            };

            const movementX = speed
            euler.setFromQuaternion( camera.quaternion );
            euler.y -= movementX * 0.002 * scope.pointerSpeed;
            /*_euler.x -= movementY * 0.002 * scope.pointerSpeed;*/
            euler.x = Math.max( PI_2 - scope.maxPolarAngle, Math.min( PI_2 - scope.minPolarAngle, euler.x ) );
            camera.quaternion.setFromEuler( euler );
            scope.dispatchEvent( changeEvent );
          }
          controls.lookLeft = function () {
            const euler = new THREE.Euler( 0, 0, 0, 'YXZ' );
            const scope = controls;
            const PI_2 = Math.PI / 2;
            const changeEvent = {
              type: 'change'
            };

            const movementX = speed
            euler.setFromQuaternion( camera.quaternion );
            euler.y += movementX * 0.002 * scope.pointerSpeed;
            /*_euler.x -= movementY * 0.002 * scope.pointerSpeed;*/
            euler.x = Math.max( PI_2 - scope.maxPolarAngle, Math.min( PI_2 - scope.minPolarAngle, euler.x ) );
            camera.quaternion.setFromEuler( euler );
            scope.dispatchEvent( changeEvent );
          }


          //event listener keyboard
          window.addEventListener('keydown', function(event){
            var name = event.key;
            var code = event.code;
            if(keyForward.includes(name)){
              moveForward()
            }
            else if(keyBackward.includes(name)){
              moveBackward()
            }
            //look right
            else if(keyRight.includes(name)){
              controls.lookRight()
            }
            //look right
            else if(keyLeft.includes(name)){
              controls.lookLeft()
            }
            else{
              //pass
            }
          });

          //the view follows the mouse after a dblClick and is released after a second dblClick
          let container = document.getElementById("container");
          container.addEventListener('dblclick', function () {
            if (controls.isLocked) {
              document.getElementById('blocker').style.display = 'none';
              controls.isLocked = false;
            } else if (!controls.isLocked) {
              controls.isLocked = true;
            } else {
            }
          });
          
          if (isMobile){
            addMobileControl();
          }
          window.addEventListener( 'resize', onWindowResize );
        }

        function addCamera(isMobile){
          //(fov, aspect ratio, near, far)
          camera = new THREE.PerspectiveCamera(89, window.innerWidth/window.innerHeight, 0.1, 222);
          camera.position.set(0,222,-1420); 
          camera.lookAt(new THREE.Vector3(0,222,102));

          //WHEEL to zoom
          let container = document.getElementById('container');
          container.addEventListener('wheel', (event) => {
            // Access scroll information from the 'event' object.
            const deltaY = event.deltaY; 

            //zoom in
            if(deltaY<0 && camera.fov>2){
              camera.fov -= 1;
            }
            //zoom out
            if (deltaY>0 && camera.fov<102){
              camera.fov += 1;

            }
            camera.updateProjectionMatrix();
          });

          //two finger pinch zoom on mobile
          function addMobileZoom(){
            let initialDistance = 0;

            function handleTouchStart(event) {
              const touches = event.touches;
              if (touches.length === 2) {
                initialDistance = calculateDistance(touches[0], touches[1]);
              }
            }

            function handleTouchMove(event) {
              const touches = event.touches
              if (touches.length === 2) {
                const currentDistance = calculateDistance(touches[0], touches[1]);
                const distanceChange = currentDistance - initialDistance;

                // Use distanceChange to determine the zoom direction
                if (distanceChange > 0) {
                  //zoom in
                  if(camera.fov>2){
                    camera.fov -= 1;
                    camera.updateProjectionMatrix();
                  }
                } else if (distanceChange < 0) {
                  //zoom out
                  if (camera.fov<120){
                    camera.fov += 1;
                    camera.updateProjectionMatrix();
                  }
                }
                // Update initial distance for the next move
                initialDistance = currentDistance;
              }
            }

            function handleTouchEnd(event) {
              // Reset initial distance when the touches end
              initialDistance = 0;
            }

            function calculateDistance(touch1, touch2) {
              const dx = touch1.clientX - touch2.clientX;
              const dy = touch1.clientY - touch2.clientY;
              return Math.sqrt(dx * dx + dy * dy);
            }

            // Attach event listeners
            let container = document.getElementById("container")
            container.addEventListener('touchstart', handleTouchStart, false);
            container.addEventListener('touchmove', handleTouchMove, false);
            container.addEventListener('touchend', handleTouchEnd, false);
          }
          if (isMobile){addMobileZoom();};
        }

        init(this.walls, this.keyBackward, this.keyForward, this.keyRight, this.keyLeft, this.speed, this.floorLength, this.isMobile);
        animate();

        function init(wallsTotDisplay,keyBackward, keyForward, keyRight, keyLeft, speed, floorLength, isMobile) {
          const container = document.getElementById('container');

          scene = new THREE.Scene();
          //add floor
          addFloor(floorLength)

          addCamera(isMobile)

          renderer = new CSS3DRenderer();
          renderer.setSize( window.innerWidth, window.innerHeight);
          container.appendChild( renderer.domElement );

          addWalls(wallsTotDisplay);

          addControls(keyBackward, keyForward, keyRight, keyLeft, speed, floorLength, isMobile);

          //addSky();
                
        }

        function onWindowResize() {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
          renderer.setSize( window.innerWidth, window.innerHeight);
        }

        function animate() {
          requestAnimationFrame( animate );
          /*controls.update();*/
          renderer.render( scene, camera );
        }
      }  
    },
    mounted(){
      this.mobileVerif()
      this.floorLength = ((this.walls.length+7)*610);
      //make the scene
      this.main()
    },
    beforeMount(){
      //mobile verif 
      this.isMobile = deviceDetector.isTouch;
    }
  }
</script>

<style type="text/css">

  #container{
    overflow: hidden;
    touch-action: none;
  }

  #controlsInfos {
    position: absolute;
    text-align: center;
    top: 5vh;
    width: 100%;
    z-index: 2;
    user-select: none;
  }

  #blocker {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }


  .aWall{
    background-color: var(--col2);
    width: 610px;
    height: 987px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1vh;
    border: .89vh solid var(--col1);
    z-index: 2;
  }
  .wallTitle{
    font-size: 5vh;
    text-decoration: underline;
  }
  .images{
    width: 100%;
  }
  .description{
    font-size:1vh;
  }
  .description:hover{
    font-size:3.22vh;
  }

  #fwd, #bwd{
    margin: 0vh;
    position: fixed;
    align-self: center;
    z-index: 1;
  }
  #fwd{
    color: var(--col1);

    bottom: 13vh;
    transform: rotate(270deg) scale(4);
  }
  #bwd{
    color: var(--col1);

    bottom: 5vh;
    transform: rotate(90deg) scale(4);
  }

</style>
