// Device detection utility class
//used in corridorView

export class DeviceDetector {
  constructor() {
    this.isTouch = this.checkTouch();
  }

  checkTouch() {
    // Check multiple indicators of touch capability
    return (
      // Check if touch events are supported
      'ontouchstart' in window ||
      // Check navigator's maxTouchPoints
      (navigator.maxTouchPoints > 0) ||
      // Check for Windows touch capability
      (navigator.msMaxTouchPoints > 0) ||
      // Check via media query for touch capability
      window.matchMedia('(pointer: coarse)').matches
    );
  }

  // Get simple device type
  getDeviceType() {
    return this.isTouch ? 'touch' : 'non-touch';
  }
}

// Create and export a singleton instance
export const deviceDetector = new DeviceDetector();

// Export default as the singleton instance
export default deviceDetector;