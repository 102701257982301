<template>
	<!-- SORT -->
	<p
		id="sorter"
		>Sort By: 
		<select v-model="sortBy" @change="sort">
			<option v-for="option in sortOptions" :value='option'>
				{{option}}
			</option>
		</select>
	</p>

	<!-- if its not all -->
	<p v-if="(who !== 'all') && !edit" id="whoBlock">
		All of <i id="who">{{who}}</i>'s Corridors
	</p>
	<div id="results">
		<Apercu 
			v-for="(corridor, index) in fetchedCorridors"
			:key="corridor.corridorId"
			:corridorInfos="corridor"
			:edit='edit'
		/>
	</div>
</template>
<script type="text/javascript">
	import Apercu from '@/components/explorer/Apercu.vue'

 	import {fetchAllCorridors, fetchTagedCorridor} from '@/firebase/firebase.js'

	export default{
		name: 'Explorer',
		components: {
			Apercu
		},
		props: ['who', 'edit'],
		data(){
			return{
				fetchedCorridors: [],
				sortOptions: ['New', 'Visits','Old'],
				sortBy: 'New'
			}
		},
		methods: {
			async sort(){
				switch(this.sortBy){
					case "New":
					  this.fetchedCorridors.sort((a, b) => {
					    const dateA = new Date(a.dateModified);
					    const dateB = new Date(b.dateModified);
					    return dateB - dateA;
					  });
					  break;

					case "Old":
					    this.fetchedCorridors.sort((a, b) => {
					      const dateA = new Date(a.dateModified);
					      const dateB = new Date(b.dateModified);
					      return dateA - dateB;
					    });
					  break;

					case "Visits":
						this.fetchedCorridors.sort((a, b) => b.numberOfVisit - a.numberOfVisit);
						break;

					default:
						this.fetchedCorridors.sort((a, b) => {
						  const dateA = new Date(a.dateModified);
						  const dateB = new Date(b.dateModified);
						  return dateB - dateA;
						});
						break;
				}
			},

		},
		async created(){
			if(this.who === 'all'){
				this.fetchedCorridors = await fetchAllCorridors();
			} else {
				this.fetchedCorridors = await fetchTagedCorridor(this.who);
			}

			//sort the fetched corridors
			//not the best but otherwise it doesnt sort well 
			this.sortBy = "Visits"
			this.sort();
			this.sortBy = "New"
			this.sort();
		}
	}
</script>

<style type="text/css" scoped>

	#whoBlock{
		font-size: 3.44vh;
		margin-top: 2.22vh;
		text-align: center;
		text-decoration: underline;
	}
	#who{
		font-weight: bold;
	}

	#sorter{
		text-align: center;
		font-size: 3.4vh;
		margin-top: 2.22vh;
		cursor: default;
	}
	select{
		width: 10vw;
		text-align: center;
		font-size: 2.22vh;
		padding: .22vh;
		cursor: pointer;
	}

	#results{
		width: 93vw;

		padding: 2vw;
		display: inline-grid;
		grid-template-columns: 33% 33% 33% ;
		gap: 2vw;
	}
	/*tablet*/
	@media only screen and (max-width: 900px){
		#results{
			max-width: 100vw;
			display: inline-grid;
			grid-template-columns: 50% 50% ;
			gap: 4.20vw;
		}
		select{
			width: 34vw;
		}
	}
	/*cell*/
	@media only screen and (max-width: 700px){
		#results{
			max-width: 100vw;
			display: flex;
			flex-direction: column;
			padding: 4vw;
			align-items: center;
		}
		select{
			width: 34vw;
		}
	}
</style>